

.align-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.style-image {
  height: 270px;
  width: auto;
}
.style-image:hover {
  cursor: pointer;
}

.MuiTableHead-root th {
  text-align: left;
}

.countries_searchbar,
.channels_dropdown,
.roles_dropdown {
  padding: 10px;
  padding-right: 40px;
  font-size: 16px;
  border-radius: 5px;
  width: 300px;
  border: 1px solid #ccc;
  margin-top: 10px;
  color: grey;
  margin-right: 30px;
}

.hint {
  color: rgba(0, 0, 0, 0.6);
  font-family: AudiTypeScreenNormal;
  font-weight: 400;
  font-size: 0.8035714285714286rem;
  line-height: 1.66;
  text-align: left;
  margin-top: 4px;
  margin-right: 14px;
  margin-bottom: 0;
  }

.channels_dropdown,
.roles_dropdown {
  margin-left: 8px;
}

.search_container {
  position: relative;
}

.search_icon {
  position: absolute;
  left: 270px;
  top: 8px;
  color: #ccc;
  margin-top: 13px;
}

.country,
.channel,
.role {
  display: flex;
  align-items: center;
}

.countries,
.channels,
.roles {
  position: relative;
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 5px;
}

.countries:hover,
.channels:hover,
.roles:hover {
  border: 1px solid black;
  cursor: text;
}

.countries > *,
.channels > *,
.roles > * {
  padding-left: 8px;
}

.search-result-options,
.no-result {
  margin: 0.8em 0.5rem;
  cursor: pointer;
}

.search-result-options:hover {
  background-color: #e8e8e8;
  border-radius: 0.2rem;
}

.remove_button {
  display: flex;
  align-items: center;
  border: 2px solid rgba(0, 0, 0, 0.87);
  background: white;
  position: absolute;
  left: 15px;
  margin-right: 20px;
  padding: 0;
}

.remove_button svg {
  font-size: 18px;
  color: rgba(0, 0, 0, 0.87);
}

.remove_button:hover {
  cursor: pointer;
  color: black;
  border: 2px solid black;
  background: red;
}

.searched_name_or_title {
  margin-left: 30px;
  color: rgba(0, 0, 0, 0.87);
}

.info {
  color: rgba(0, 0, 0, 0.87);
}

.countries_title,
.channels_title,
.roles_title {
  margin-top: 15px;
  margin-bottom: 10px;
  margin-left: 13px;
  color: rgba(0, 0, 0, 0.6);
}

.input_label {
  margin: 15px 0 10px 13px;
}

.save_button {
  background: black;
  color: white;
  border: none;
  font-size: 14px;
  padding: 10px 16px;
  display: flex;
  vertical-align: middle;
  transition: background 0.3s;
  border-radius: 5px;
}

.save_button:hover {
  background-color: rgba(50, 50, 50, 1);
  cursor: pointer;
}

.save_text {
  vertical-align: middle;
  margin: auto;
  margin-left: 6px;
}

/* ====================== Dealership ============== */

.notFound {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100px;
  margin: 20px;
  background-color: #f2f2f2;
  color: #333;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 18px;
  text-align: center;
}

/*Profile page*/

.profile-container {
  margin-top: 10px;
  padding: 20px;
  background: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.profile-details {
  margin: 20px 0;
}

.profile-field {
  margin-bottom: 10px;
  font-size: 16px;
}

.profile-field strong {
  font-weight: 600;
  margin-right: 5px;
}

.profile-field span {
  font-weight: 400;
  color: #555;
}

/* miscellaneous */

.crud_buttons {
  display: flex;
  flex-direction: column;
  vertical-align: middle;
  margin-left: auto;
  max-width: 120px;
}

/*pagination*/

.paginationContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
}

.paginationButton {
  margin: 0 4px;
  padding: 5px 10px;
  background-color: #fff;
  border: 1px solid #ccc;
  cursor: pointer;
}

.paginationButton:hover {
  background-color: #f0f0f0;
}

.paginationButton:disabled,
.paginationButton.active {
  /* Style for the current page and disabled button */
  background-color: #ccc;
  cursor: default;
}

.MuiTablePagination-displayedRows {
  display: none;
}

.MuiTablePagination-actions {
  display: flex;
  margin: 0 auto;
  padding: 10px;
  align-items: center;
  justify-content: center;
}
.MuiTablePagination-root {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.MuiTablePagination-spacer {
  display: none;
}

.MuiPagination-ul li button {
  margin: 0 4px;
  padding: 5px 10px !important;
  background-color: #fff;
  border: 1px solid #ccc !important;
  margin: 5px !important;
  cursor: pointer;
  border-radius: 0px !important;
  font-size: 13px !important;
}
.MuiPagination-ul li button:hover {
  background-color: #f0f0f0;
}

.MuiPagination-ul li:nth-child(5) button svg {
  display: none;
}

.MuiPagination-ul li:nth-child(1) button svg {
  display: none;
}

.MuiPagination-ul li:nth-child(5) button::after {
  content: "Next" !important;
}

.MuiPagination-ul li:nth-child(1) button::after {
  content: "Prev" !important;
}

.splashScreenTextarea {
  min-height: 100px;
  resize: vertical;
}

.resource-title {
  font-size: 0.9rem;
  margin-bottom: 0.2em;
  color: rgba(0, 0, 0, 0.6);
}

.empty-resource {
    font-size: 0.9rem
}

.resource-name{
    font-size: 0.9rem;
    margin-bottom: 5px
}

.search-bar {
    display: flex;
    flex-wrap: wrap
}

.not-found {
  display: flex;
  justify-content: center;
  margin-top: 300px;
}

.not-found-message {
  color: rgba(0, 0, 0, 0.87);
  text-align: center;
}

.MuiButtonBase-root.MuiRadio-root.Mui-checked,
.RaRadioButtonGroupInput-labe {
  color: rgba(0, 0, 0, 0.6);
}
