.error_component {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto;
    padding: 2rem;
    max-width: 600px;
    text-align: center;
    color: #333;
    font-family: Arial, sans-serif; /* Change this to the font you are using */
}

.error_heading {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 2rem;
    color: #d32f2f;
    margin-bottom: 1rem;
}

.error_heading svg {
    width: 50px;
    height: 50px;
    margin-bottom: 0.5rem;
}

.error_component > div {
    font-size: 1rem;
    color: #666;
    margin-bottom: 1rem;
}

.error_back_button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 0.5rem 1rem;
    font-size: 1rem;
    font-weight: bold;
    color: #fff;
    background-color: #1976d2;
    border: none;
    border-radius: 5px;
    text-transform: uppercase;
    transition: background-color 0.3s ease;
    outline: none;
    margin-top: 1rem; /* Added margin-top for spacing */
}

.error_back_button:hover {
    background-color: #1565c0;
    cursor: pointer;
}

.error_back_button svg {
    margin-right: 0.5rem;
    fill: #fff;
    width: 20px;
    height: 20px;
}


details summary {
    font-weight: bold;
    cursor: pointer;
}

details h2 {
    font-size: 1rem;
    font-weight: normal;
    word-wrap: break-word;
    background-color: #f8f8f8;
    padding: 1rem;
    border-radius: 5px;
    margin-top: 0.5rem;
}
