.login_div{
    display:flex;
    width:300px;
    margin:0 auto;
    flex-direction:column;
    height: 100vh;
    align-items:center;
    justify-content:center;
}

.login_page_title{
    width: 800px;
    margin-top:20px;
    margin-bottom:60px;
    font-size:40px;
    text-align:center;
}

.login_button{
    width:200px;
    margin: 0 auto;
    padding:10px;
    font-size:18px;
    background:lightgrey;
    border-radius:5px;
    border:1px solid black;
    margin-bottom:20px;
}

.login_button:hover{
    background:white;
    cursor:pointer;
}

.login_page_version {
    font-size: 10px;
}