@font-face {
  font-family: 'AudiTypeScreenBold';
  src:
      local('AudiTypeScreenBold'),url('./fonts/AudiTypeScreen/AudiTypeScreen-Bold.eot')
      format('eot'),
      local('AudiTypeScreenBold'),url('./fonts/AudiTypeScreen/AudiTypeScreen-Bold.woff')
      format('woff'),
      local('AudiTypeScreenBold'),url('./fonts/AudiTypeScreen/AudiTypeScreen-Bold.woff2')
      format('woff2'),
      local('AudiTypeScreenBold'),url('./fonts/AudiTypeScreen/AudiTypeScreen-Bold.svg')
      format('svg');
  font-display: swap;
}

@font-face {
  font-family: 'AudiTypeScreenNormal';
  src:
      local('AudiTypeScreenNormal'),url('./fonts/AudiTypeScreen/AudiTypeScreen-Normal.eot')
      format('eot'),
      local('AudiTypeScreenNormal'),url('./fonts/AudiTypeScreen/AudiTypeScreen-Normal.woff')
      format('woff'),
      local('AudiTypeScreenNormal'),url('./fonts/AudiTypeScreen/AudiTypeScreen-Normal.woff2')
      format('woff2'),
      local('AudiTypeScreenNormal'),url('./fonts/AudiTypeScreen/AudiTypeScreen-Normal.svg')
      format('svg');
  font-display: swap;
}

@font-face {
  font-family: 'AudiTypeScreenLight';
  src:
      local('AudiTypeScreenLight'),url('./fonts/AudiTypeScreen/AudiTypeScreen-Light.eot')
      format('eot'),
      local('AudiTypeScreenLight'),url('./fonts/AudiTypeScreen/AudiTypeScreen-Light.woff')
      format('woff'),
      local('AudiTypeScreenLight'),url('./fonts/AudiTypeScreen/AudiTypeScreen-Light.woff2')
      format('woff2');
  font-display: swap;
}




body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body, button, input, select, textarea {
  font-family:'AudiTypeScreenNormal', sans-serif;
}