@use "sass:math";
.loader {
    @keyframes spin {
        0% {
            transform: rotate(0deg);
        }

        100% {
            transform: rotate(360deg);
        }
    }

    position: relative;
    margin:auto;
    z-index:100;
    display: none;

    &.is-inline {
        position: relative;
        position: unset;
        top: unset;
        left: unset;
        display: inline-block;
        transform: unset;
    }

    &.is-grey {
        filter: grayscale(80%);
    }

    .b-login-container & {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 500px;
        margin: 0;
        transform: translate(-50%, -50%);
    }

    &.is-visible {
        display: block;
    }

    .loader-icon {
        position:relative;
        $preloader-size: 100px;
        // $preloader-color: #bb0a30;
        $preloader-color: #f50034;
        $preloader-background: #b3b3b3;

        @keyframes rotate {
            to {
                transform: rotate(360deg);
            }
        }

        @keyframes spin {
            0% {
                transform: rotate(135deg);
            }

            50% {
                transform: rotate(-35deg);
            }

            100% {
                transform: rotate(135deg);
            }
        }

        %aui-preloader {
            box-sizing: border-box;
            position: absolute;
            top: 0;
            left: 0;
            display: block;
            width: $preloader-size;
            height: $preloader-size;
        }

        .aui-preloader {
            @extend %aui-preloader;

            position: relative;
            top: calc(50% - #{math.div($preloader-size, 2)});
            left: calc(50% - #{math.div($preloader-size, 2)});
            animation: rotate 1.5s cubic-bezier(.4, 0, 0, 1) infinite both;

            &::before {
                content: "";
                position: absolute;
                inset: 1px;
                border: 1px solid $preloader-background;
                border-radius: 50%;
            }

            &__spinner {
                @extend %aui-preloader;

                animation: rotate 1.5s cubic-bezier(.75, .02, .5, 1) infinite both;
            }

            &__clipper {
                @extend %aui-preloader;

                overflow: hidden;
                width: #{math.div($preloader-size, 2)};
                border-color: inherit;
            }

            &__circle {
                @extend %aui-preloader;

                border: 5px solid;
                border-color: $preloader-color transparent transparent $preloader-color;
                border-radius: 50%;
                transform: rotate(-35deg);
                animation: spin 1.5s cubic-bezier(.75, .02, .5, 1) infinite both;
            }
        }
    }

    .loader-icon--is-small {
        $preloader-small-size: 25px;
        // $preloader-color: #bb0a30;
        $preloader-color: #f50034;
        $preloader-background: #b3b3b3;

        @keyframes rotate {
            100% {
                transform: rotate(360deg);
            }
        }

        @keyframes spin {
            0% {
                transform: rotate(135deg);
            }

            50% {
                transform: rotate(-35deg);
            }

            100% {
                transform: rotate(135deg);
            }
        }

        %aui-preloader {
            box-sizing: border-box;
            position: absolute;
            top: 0;
            left: 0;
            display: block;
            width: $preloader-small-size;
            height: $preloader-small-size;
        }

        .aui-preloader {
            @extend %aui-preloader;

            position: relative;
            top: calc(50% - #{math.div($preloader-small-size, 2)});
            left: calc(50% - #{math.div($preloader-small-size, 2)});
            animation: rotate 1.5s cubic-bezier(.4, 0, 0, 1) infinite both;

            &::before {
                content: "";
                position: absolute;
                inset: 1px;
                border: 1px solid $preloader-background;
                border-radius: 50%;
            }

            &__spinner {
                @extend %aui-preloader;

                animation: rotate 1.5s cubic-bezier(.75, .02, .5, 1) infinite both;
            }

            &__clipper {
                @extend %aui-preloader;

                overflow: hidden;
                width: #{math.div($preloader-small-size, 2)};
                border-color: inherit;
            }

            &__circle {
                @extend %aui-preloader;

                border: 5px solid;
                border-color: $preloader-color transparent transparent $preloader-color;
                border-radius: 50%;
                transform: rotate(-35deg);
                animation: spin 1.5s cubic-bezier(.75, .02, .5, 1) infinite both;
            }
        }
    }
}